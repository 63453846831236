<template>
  <section>
    <div class="row">
      <div class="col-sm-12 col-md-8">
        <div class="col-md-12" style="position:absolute; z-index:1; bottom:5%; display:flex; justify-content:center;">
          <button class="btn btn-icon btn-lg btn-icon-lg btn-light btn-circle" style="margin-right:8px;" @click="changeCameraStatus()">
            <font-awesome-icon v-if="isCameraOpen" icon="fa-solid fa-video" />
            <font-awesome-icon v-else icon="fa-solid fa-video-slash" />
          </button>
          <button class="btn btn-icon btn-lg btn-icon-lg btn-light btn-circle" style="margin-left:8px;" @click="changeMicrophoneStatus()">
            <font-awesome-icon v-if="isMicrophoneOpen" icon="fa-solid fa-microphone" />
            <font-awesome-icon v-else icon="fa-solid fa-microphone-slash" />
          </button>
        </div>

        <div v-show="connections.LocalCamera == 1" style="width: 100%">
          <video style="width: 100%" ref="landingPlayer" id="landingPlayer" class="video-js" muted autoplay></video>
        </div>

        <div v-show="connections.LocalCamera != 1"
             style="height: 500px !important; background-color: #000 !important; color:darkred; font-size: 45px !important; text-align: center;">
          {{ $t('OnlineMeetingComponent.CameraClosed') }}
        </div>
      </div>
      <div class="col-sm-12 col-md-4">
        <b-alert v-if="connections.LocalCamera == 0" :show="true" variant="warning" style="margin-bottom: 0px !important">
          <span class="alert-text kt-font-bold kt-font-dark">
            <font-awesome-icon icon="fa fa-check" size="lg" />
            {{ $t('OnlineMeetingComponent.CameraPermissionInformation') }}
          </span>
        </b-alert>
        <b-alert v-if="connections.LocalCamera == 1" :show="true" variant="success" style="margin-bottom: 0px !important">
          <span class="alert-text kt-font-bold kt-font-dark">
            <font-awesome-icon icon="fa fa-check" size="lg" />
            {{ $t('OnlineMeetingComponent.CameraPermissionSuccess') }}
          </span>
        </b-alert>
        <b-alert v-if="connections.LocalCamera == -1" :show="true" variant="danger" style="margin-bottom: 0px !important">
          <span class="alert-text kt-font-bold kt-font-dark">
            <font-awesome-icon icon="fa fa-exclamation-triangle" size="lg" />
            {{ $t('OnlineMeetingComponent.CameraPermissionError') }}
          </span>
        </b-alert>
        <br />
        <section v-if="connections.LocalCamera == 1">
          <h4>{{ $t('OnlineMeetingComponent.MeetingStatus') }}</h4>
          <section v-if="!isMeetingStarted">
            <div v-if="!connections.SignalR" class="alert alert-info">
              <i class="fa fa-user-clock kt-margin-5"></i> &nbsp; {{ $t('OnlineMeetingComponent.WaitingAttendees') }}
            </div>
            <div v-else-if="connections.SignalR">
              <div class="alert alert-outline-success">
                <i class="fa fa-check kt-margin-5"></i>
                <span v-if="isHost">{{ $t('OnlineMeetingComponent.YouCanStartMeeting') }}</span>
                <span v-else>{{ $t('OnlineMeetingComponent.WaitingHostForStart') }} </span>
              </div>
              <button @click="startOnlineMeeting()" v-if="isHost && connections.LocalCamera == 1" class="btn btn-block btn-info"><i class="fa fa-play"></i> {{ $t('OnlineMeetingComponent.StartMeeting') }}</button>
            </div>
          </section>
          <section v-else>
            <div v-if="isHost" class="alert alert-outline-info">
              <i class="fa fa-check kt-margin-5"></i>
              <span>{{ $t('OnlineMeetingComponent.WaitingGuestForAccept') }}</span>
            </div>
            <div v-else>
              <div v-if="!showSpinnerButton">
                <div class="alert alert-outline-success">
                  <i class="fa fa-check kt-margin-5"></i>
                  <span>{{ $t('OnlineMeetingComponent.YouCanJoinMeeting') }}</span>
                </div>
                <button @click="joinOnlineMeeting()" class="btn btn-block btn-info">
                  <i class="fa fa-play"></i> {{ $t('OnlineMeetingComponent.JoinMeeting') }}
                </button>
              </div>
              <div v-else>
                <br />
                <button class="btn btn-block btn-outline-info  kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--info">
                  {{ $t('OnlineMeetingComponent.RedirectToMeeting') }}
                </button>
              </div>
            </div>
          </section>
        </section>
        <meeting-invitation-component v-if="!connections.SignalR && isHost"
                                      :SignalRConnectionActive="connections.SignalR"
                                      :meeting-url="meetingUrl"></meeting-invitation-component>
      </div>
    </div>
  </section>
</template>

<script>
  import videojs from 'video.js/dist/alt/video.core.novtt.min.js'
  import MeetingInvitationComponent from './MeetingInvitationComponent.vue'

  export default {
    name: 'MeetingLandingComponent',
    props: {
      connections: {},
      isHost: Boolean,
      meetingUrl: String
    },
    components: {
      MeetingInvitationComponent
    },
    data: function () {
      return {
        isCameraOpen: true,
        isMicrophoneOpen: true,
        showSpinnerButton: false,
        gdpr: false,
        cameraAndMicAccess: false
      }
    },
    methods: {
      initPlayer: function () {
        const $this = this;
        videojs($this.$refs.landingPlayer, $this.options, function onPlayerReady() {

          if ($this.isLocalStreamStarted) {
            const localMediaStream = new MediaStream($this.localStream);
            let vid = document.getElementById('landingPlayer').getElementsByTagName("video")[0];
            vid.srcObject = localMediaStream;
            vid.play();
          }
          else {
            console.error("meeting stream is null on MeetingLandingComponent")
          }
        })
      },
      startMeeting() {
        const $this = this;
        this.$store.dispatch('Meeting/initializeSimplepeer', { isHost: $this.isHost, isStartingMeeting: true });
      },
      changeCameraStatus() {
        if (this.isCameraOpen) {
          this.isCameraOpen = false;
          this.$parent.isCameraOpen = false;
          this.localStream.getVideoTracks()[0].enabled = false;
        }
        else {
          this.isCameraOpen = true;
          this.$parent.isCameraOpen = true;
          this.localStream.getVideoTracks()[0].enabled = true;
        }
      },
      changeMicrophoneStatus() {
        if (this.isMicrophoneOpen) {
          this.isMicrophoneOpen = false;
          this.$parent.isMicrophoneOpen = false;
          this.localStream.getAudioTracks()[0].enabled = false;
        }
        else {
          this.isMicrophoneOpen = true;
          this.$parent.isMicrophoneOpen = true;
          this.localStream.getAudioTracks()[0].enabled = true;
        }
      },
      startOnlineMeeting() {
        this.$parent.offerP2pConnection();
      },
      joinOnlineMeeting() {
        this.$parent.acceptP2pConnection();
      }
    },
    computed: {
      localStream: function () {
        return this.$store.state.Meeting.meetingStream;
      },
      isLocalStreamStarted: function () {
        return this.localStream != null;
      },
      isMeetingStarted: function () {
        return this.$store.getters['Meeting/isMeetingStarted'];
      },
    },
    watch: {
      isLocalStreamStarted: function (val) {
        const self = this;
        if (val) {
          self.initPlayer();
        }
      }
    }
  }
</script>
